.search-form {
  display: flex;
  margin-bottom: 10px;
}

.search-input {
  flex: 1;
  padding: 5px;
  font-size: 16px;
}

.search-button {
  padding: 5px 10px;
  font-size: 16px;
  background-color: #444;
  color: white;
  border: none;
  cursor: pointer;
}

.search-button:hover {
  background-color: #666;
}

.leaflet-control-zoom a {
  background-color: #444; 
  color: white;          
  font-size: 18px;        
  text-align: center;     
  width: 30px;            
  height: 30px;           
  line-height: 30px;      
  border: none;           
  border-radius: 4px;     
}

.leaflet-control-zoom a:hover {
  background-color: #666; 
}

.leaflet-control-zoom {
  border: 2px solid #444; 
  border-radius: 4px;     
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  margin: 2px;           
}

.container-popupmap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  border-radius: 0.5rem;
}

.title-popupmap{
  font-size: 20px;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 0.5rem;
}

.map-container {
  height: 60%;
  width: 100%;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
}

.popup-content {
  background-color: white;
  border-radius: 0.5rem;
  padding: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-title {
  font-size: 0.875rem;
  font-weight: 600;
  color: #2d3748;
}

.remove-button {
  margin-top: 0.5rem;
  background-color: #f56565;
  color: white;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  outline: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.remove-button:hover {
  background-color: #e53e3e;
}

.remove-button:focus {
  box-shadow: 0 0 0 3px rgba(229, 62, 62, 0.5);
}

.clear-button {
  background-color: #f56565;
  color: white;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  outline: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.clear-button:hover {
  background-color: #e53e3e;
}

.clear-button:focus {
  box-shadow: 0 0 0 3px rgba(229, 62, 62, 0.5);
}

.button-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
}
