/* Updated Search Component Styles */

.search-component {
  padding: 20px;
  background-color: #f5f5f5;
}

.search-container {
  max-width: 1200px;
  margin: 0 auto;
}

.search-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.search-col {
  flex: 1;
  min-width: 250px;
}

.search-input {
  width: 100%;
  padding: 12px 20px;
  border: 2px solid #ddd;
  border-radius: 30px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.search-input:focus {
  border-color: #8bc34a;
  box-shadow: 0 0 0 2px rgba(139, 195, 74, 0.2);
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 5px;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #555;
  cursor: pointer;
  font-weight: 700;
}

.checkbox-input {
  width: 18px;
  height: 18px;
  margin: 12px;
}


.stats-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
}

.stat-item {
  flex: 1;
  min-width: 200px;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.stat-icon {
  font-size: 24px;
  margin-right: 15px;
  color: #8bc34a;
}

.stat-text {
  font-size: 16px;
  color: #333;
}

.stat-value {
  color: #555;
  font-weight: bold;
  margin-left: 5px;
}

@media (max-width: 768px) {
  .search-row {
    flex-direction: column;
  }

  .search-col {
    width: 100%;
  }

  .filters {
    flex-direction: column;
    align-items: flex-start;
  }

  .checkbox-group {
    margin-bottom: 10px;
  }

  .stats-container {
    flex-direction: column;
  }

  .stat-item {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .stats-container {
    justify-content: flex-start;
  }

  .stat-item {
    max-width: 100%;
    margin: 10px 0;
  }

  .filters {
    padding: 0 10px;
  }
}

/* BlogCard.css */
.card-section {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f8f9fa;
}

.card-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.card-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: left;
}

.card-col {
  flex: 0 0 calc(20% - 10px);
  max-width: calc(20% - 10px);
  margin-bottom: 20px; 
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
}

.blogCardItem {
  background-color: #8bc34a;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border: 2px solid #8bc34a;
  height: 530px;
  display: flex;
  flex-direction: column;
}

.blogCardImage {
  width: 100%;
  height: 275px;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blogCardContent {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.blogCardDetails {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.card-plantName {
  width: 200px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0;
  color: #efefef;
  margin-left: 5px;
}

.card-detail {
  font-weight: bold;
  font-size: 12px;
  color: #333;
  text-align: left;
  word-break: break-word;
  margin: 0px;
  margin-left: 5px;
}

.more-info-btn {
  font-weight: bold;
  font-size: 15px;
  color: #333;
  text-align: left;
  word-break: break-word;
  margin-left: 5px;
}

.verified {
  font-size: 15px;
  margin-left: 5px;
}

.plantedBy {
  font-weight: bold;
  font-size: 18px;
  color: black;
  text-align: left;
  word-break: break-word;
  margin-left: 5px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .card-col {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}

@media (max-width: 600px) {
  .card-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
