.stats-boxes {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .stat-box {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 30%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .stat-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .stat-box h3 {
    margin-top: 10px;
    font-size: 1.2em;
  }
  
  .stat-box p {
    font-size: 1.5em;
    margin: 10px 0 0;
  }
  