#bannerTitle
{
    position: absolute;
    top: 20%;
    right: 10%;
    width: clamp(400px, 30%, 500px);
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    gap: 10px;
}

#bannerTitle h4
{
    font-size: 24px;
}

#bannerTitle .titleButtons
{
    width: 100%;
    display: inline-flex;
}

#bannerTitle button
{
    background-color: green;
    width: calc(50% - 5px);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    /* box-shadow: 0 0 5px #eee; */
    border-radius: 5px;
    opacity: 0.4;
    margin-left: 5px;
}

#bannerTitle button:hover
{
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    opacity: 1;
    text-decoration: none;
}

@media (max-width: 992px)
{
    #bannerTitle
    {
        top: 20px;
        right: 10%;
    }

    #bannerTitle h4
    {
        font-size: 18px;
    }

    #bannerTitle h1
    {
        font-size: 37.5px;
        line-height: normal;
    }

    #bannerTitle button
    {
        height: 40px;
        opacity: 1;
    }
}

@media (max-width: 576px)
{
    #bannerTitle
    {
        position: relative;
        top: 0;
        width: 100%;
        right: 0;
        background-color: rgb(34, 34, 34);
        padding: 10px;
    }

    #bannerTitle h4
    {
        font-size: 12px;
    }

    #bannerTitle h1
    {
        font-size: 25px;
        line-height: normal;
    }

    

    #bannerTitle button
    {
        height: 30px;
        opacity: 1;
    }
}