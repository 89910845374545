/* .countdown {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .countdown-item {
    text-align: center;
  }
  
  .countdown-time {
    font-size: 2rem;
    color: black;
    font-weight: bold;
  }
  
  .countdown-label {
    display: block;
    font-size: 1rem;
    color: black;
  }
   */

/* countdown.css */

.countdown-wrapper
{
    text-align: center;
    padding: 40px 10px;
    background-color: #011118;
}

.countdown-heading
{
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 20px;
}

.countdown
{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.countdown-item
{
    flex: 1;
    max-width: 200px;
    text-align: center;
    background-color: #ffeb3b;
    /* Background color */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.countdown-time
{
    font-size: 3rem;
    color: #060606;
    font-weight: bold;
}

.countdown-label
{
    display: block;
    font-size: 1.2rem;
    color: #0e0e0e;
}

@media (max-width: 576px)
{

    .countdown-item
    {
        padding: 10px;
    }

    .countdown-time
    {
        font-size: 1.5rem;
    }

    .countdown-label
    {
        font-size: 0.8rem;
    }
}