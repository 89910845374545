.aboutUsComp
{
    width: 33%;
    max-width: 150px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 30px;
}

.aboutUsComp h4
{
    font-size: 44px;
}

.aboutUsCompImg
{
    position: absolute;
    right: -40px;
    bottom: 0px !important;
    height: auto;
    width: 75%;
    opacity: 0.5 !important;
}

@media (max-width: 576px)
{
    .aboutUsComp h4
    {
        font-size: 24px;
    }
}