.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    background-color: #f0f2f5;
  }
  
  .login-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 500px;
  }
  
  .login-form h2 {
    padding-bottom: 5px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .login-form-group {
    margin-bottom: 15px;
    font-weight: bolder;
  }
  
  .login-form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .login-form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #000;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #8BC34A;
    color: #fff;
    border: none;
    font-weight: bolder;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-options {
    margin-top: 10px;
    text-align: center;
    font-weight: bolder;
  }
  
  .option-link {
    display: block;
    color: #007bff;
    text-decoration: none;
    margin-top: 5px;
  }
  
  .option-link:hover {
    text-decoration: underline;
  }
  