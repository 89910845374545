.forget-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    background-color: #f0f2f5;
  }
  
  .forget-password-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 500px;
  }
  
  .forget-password-form h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .forget-form-group {
    margin-bottom: 15px;
  }
  
  .forget-form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .forget-form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #000;
  }
  
  .send-otp-button, .reset-password-button {
    width: 100%;
    padding: 10px;
    background-color: #8BC34A;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  p{
    display: inline;
    padding: 5px;
    font-weight: bold;
    color: #525252;
  }
  .forget-navigation{
    text-align: center;
    display:flex;
  }