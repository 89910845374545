.form-control {
    cursor: pointer;
  }
  
  .invalid-field {
    border: 1px solid red;
  }
  

.content-upload{
  flex-grow: 1;
    padding: 20px;
}
h3{
  margin-bottom: 3px;
}