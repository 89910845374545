.photo-upload-form {
    width: 60%;
    margin: auto;
    padding: 20px;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    margin:10px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .gallery {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .gallery-item-dashboard {
    width: 400px;
    border: 1px solid #ffecec;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .gallery-item-dashboard img {
    width: 100%;
    border-radius: 10px;
  }
  
  .gallery-item-dashboard button {
    background-color: #dc3545;
    margin-top: 10px;
  }

  .gallery-item-dashboard .edit-btn {
    background-color: rgb(73, 157, 73);
    margin-top: 10px;
  }
  
  .gallery-item-dashboard .edit-btn:hover {
    background-color: rgb(55, 114, 55);
    margin-top: 10px;
  }

  .gallery-item-dashboard button:hover {
    background-color: #c82333;
  }
  