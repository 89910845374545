/* src/components/MultiLineChart.css */

.chart-container {
    margin: 20px;
    padding: 20px;
    border: solid 1px;
    border-radius: 10px;
  }
  
  .filter-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .filter-select {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .chart-wrapper {
    width: 100%;
    height: 500px;
  }
  
  .table-container {
    display: flex;
    justify-content: center;
    overflow-x: auto;
  }
  
  .data-table {
    width: 80%;
    margin-top: 20px;
    border-collapse: collapse;
    text-align: center;
  }
  
  .data-table th,
  .data-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  @media (max-width: 768px) {
    .chart-container {
      margin: 10px;
      padding: 10px;
    }
  
    .filter-select {
      padding: 8px;
      font-size: 14px;
    }
  
    .chart-wrapper {
      height: 300px;
    }
  
    .data-table {
      width: 100%;
      margin-top: 10px;
    }
  
    .data-table th,
    .data-table td {
      padding: 6px;
      font-size: 12px;
    }
  }
  
  @media (max-width: 480px) {
    .filter-container {
      flex-direction: column;
      align-items: center;
    }
  
    .filter-select {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .chart-wrapper {
      height: 200px;
    }
  }
  