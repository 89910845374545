.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background-color: #f0f2f5;
  }
  
  .signup-form {
    margin: 5px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 500px;
  }
  
  .signup-form h2 {
    padding: 5px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .signup-form-group {
    margin-bottom: 15px;
  }
  
  .signup-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bolder;
  }
  
  .signup-form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #000;
  }
  
  .signup-button {
    width: 100%;
    padding: 10px;
    background-color: #8BC34A;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .signup-options {
    margin-top: 10px;
    text-align: center;
  }
  
  .option-link {
    display: block;
    color: #007bff;
    text-decoration: none;
    margin-top: 5px;
    font-weight: bold;
  }
  
  .option-link:hover {
    text-decoration: underline;
  }
   
  .error-message {
    color: red;
    font-size: 0.8em;
    margin-top: 5px;
  }
  
  p{
    display: inline;
    padding: 5px;
    font-weight: bold;
    color: #525252;
  }
  .signup-navigation{
    text-align: center;
    display:flex
  }